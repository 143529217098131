export const environment = {
  production: false,

  API_DOMAINS: {
    // ----- SERVER QA -----
    // LOGIN: 'https://serhafen-back-qa.com/auth',
    // USERS: 'https://serhafen-back-qa.com/users',
    // MAWB: 'https://serhafen-back-qa.com/awb',
    // AWB_HEADER: 'https://serhafen-back-qa.com/awb/header',
    // MASTER_FLIGHTS: 'https://serhafen-back-qa.com/flights',
    // TRUCK_TRAVEL: 'https://serhafen-back-qa.com/truck/travel',
    // TRUCKS: 'https://serhafen-back-qa.com/truck/travel/trucks',
    // COMPANY: 'https://serhafen-back-qa.com/truck/travel/companies',
    // TYPE_TRAVEL: 'https://serhafen-back-qa.com/truck/travel/type',
    // FLIGHTS: 'https://serhafen-back-qa.com/flights',
    // MAWB_DETAIL: 'https://serhafen-back-qa.com/mawb',
    // AWB_ROUTE: 'https://serhafen-back-qa.com/awb/detail/route',
    // FLOW: 'https://www.serhafen-back-qa.com/flow',
    // STATUS_CODES: 'https://www.serhafen-back-qa.com/status-code',
    // KEY_WORDS: 'https://www.serhafen-back-qa.com/key-word',
    // EXTERNAL: 'https://www.serhafen-back-qa.com/external',
    // RECEIVED_MESSAGES: 'https://www.serhafen-back-qa.com/received-messages',
    // COUNTRY: 'https://www.serhafen-back-qa.com/country',
    // NEWS: 'https://www.serhafen-back-qa.com/news',
    // ENV: 'QA',

    // new coso
    LOGIN: 'https://api.serhafen.tech/auth',
    USERS: 'https://api.serhafen.tech/users',
    MAWB: 'https://api.serhafen.tech/awb',
    AWB_HEADER: 'https://api.serhafen.tech/awb/header',
    MASTER_FLIGHTS: 'https://api.serhafen.tech/flights',
    TRUCK_TRAVEL: 'https://api.serhafen.tech/truck/travel',
    TRUCKS: 'https://api.serhafen.tech/truck/travel/trucks',
    COMPANY: 'https://api.serhafen.tech/truck/travel/companies',
    TYPE_TRAVEL: 'https://api.serhafen.tech/truck/travel/type',
    FLIGHTS: 'https://api.serhafen.tech/flights',
    MAWB_DETAIL: 'https://api.serhafen.tech/mawb',
    AWB_ROUTE: 'https://api.serhafen.tech/awb/detail/route',
    FLOW: 'https://api.serhafen.tech/flow',
    STATUS_CODES: 'https://api.serhafen.tech/status-code',
    KEY_WORDS: 'https://api.serhafen.tech/key-word',
    EXTERNAL: 'https://api.serhafen.tech/external',
    RECEIVED_MESSAGES: 'https://api.serhafen.tech/received-messages',
    COUNTRY: 'https://api.serhafen.tech/country',
    NEWS: 'https://api.serhafen.tech/news',
    ENV: 'LOCAL',

    // ----- SERVER LOCAL -----
    // LOGIN: 'http://localhost:3000/auth',
    // USERS: 'http://localhost:3000/users',
    // MAWB: 'http://localhost:3000/awb',
    // AWB_HEADER: 'http://localhost:3000/awb/header',
    // MASTER_FLIGHTS: 'http://localhost:3000/flights',
    // TRUCK_TRAVEL: 'http://localhost:3000/truck/travel',
    // TRUCKS: 'http://localhost:3000/truck/travel/trucks',
    // COMPANY: 'http://localhost:3000/truck/travel/companies',
    // TYPE_TRAVEL: 'http://localhost:3000/truck/travel/type',
    // FLIGHTS: 'http://localhost:3000/flights',
    // MAWB_DETAIL: 'http://localhost:3000/mawb',
    // AWB_ROUTE: 'http://localhost:3000/awb/detail/route',
    // FLOW: 'http://localhost:3000/flow',
    // STATUS_CODES: 'http://localhost:3000/status-code',
    // KEY_WORDS: 'http://localhost:3000/key-word',
    // EXTERNAL: 'http://localhost:3000/external',
    // RECEIVED_MESSAGES: 'http://localhost:3000/received-messages',
    // COUNTRY: 'http://localhost:3000/country',
    // NEWS: 'http://localhost:3000/news',
    // ENV: 'LOCAL',
  },
  GLOBAL_SETTINGS: {
    MESSAGE_LOADING_INFORMATION: 'Cargando información, por favor espere un momento ...',
    MESSAGE_PROCESSING_INFORMATION: 'Procesando información, por favor espere un momento ...',
    GENERATING_DOCUMENT: 'Generando documento, por favor espere un momento ...',
  },
  TYPE: 'pc',
};
